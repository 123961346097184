import { Box, Typography } from '@mui/material';

export default function LeasingConditionsNotice(props) {
  return (
    <Box {...props}>
      <Typography component="div" sx={{ color: 'grey.700' }} variant="caption">
        <sup>II.</sup>
        {' '}
        Ein unverbindliches Kilometerleasingangebot (Bonität vorausgesetzt) der
        HYUNDAI Finance, ein Geschäftsbereich der Hyundai Capital Bank Europe GmbH,
        Friedrich-Ebert-Anlage 35-37, 60327 Frankfurt am Main,
        für den Hyundai TUCSON Facelift Select (Benziner) 110 kW (160 PS); nach
        WLTP, Verbrauch kombiniert (6,8 l/100 km); CO2 Emissionen kombiniert (154g/km);
        mögliche Energiekosten bei 15.000 km
        Jahreslaufleistung (1.900,26,- ); mögliche CO2 Kosten über die nächsten 10
        Jahre, niedriger Preis (1.155,00,-); mittlerer Preis (2.656,50,-); hoher
        Preis (4.389,00,-); jährliche KFZ-Steuer (163,-);
        Leasingsonderzahlung: 0,- ; Laufzeit: 48 Monate; mtl. Leasingraten: 199,- ;
        Laufleistung: 5.000 km/Jahr. Alle Preisangaben inkl. MwSt.; zzgl.
        Überführungskosten. Privatkundenangebot, gültig bis zum 31.12.2024.
        Mehr- und Minderkilometer sowie eventuell vorhandene Schäden werden nach
        Vertragsende gesondert abgerechnet. Über alle Detailbedingungen informiert
        Sie gerne Ihr teilnehmender Hyundai-Partner. Das
        abgebildete Fahrzeug enthält möglicherweise aufpreispflichtige Ausstattungen,
        die nicht Bestandteil des Leasing-Angebots sind. Änderungen und Irrtümer vorbehalten.
        <br />
        <br />
        Zwischenverkauf und Irrtümer vorbehalten. Die Fahrzeugbeschreibung dient
        ausschließlich der allgemeinen Identifikation des Fahrzeugs und stellt keine
        rechtsverbindliche Gewährleistung dar. Verbindlich sind nur
        die Vereinbarungen im Kaufvertrag und der Auftragsbestätigung. Bitte beachten
        Sie, dass bestimmte Sonderausstattungen zusätzliche Kosten verursachen können.
        Detaillierte Informationen zum Ausstattungsumfang erhalten Sie von unserem Verkaufspersonal.
      </Typography>
    </Box>
  );
}
